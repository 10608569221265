// import * as React from "react";
import React, { useEffect } from 'react';
// import { graphql, Link } from "gatsby"
// import "../../css/talent.css";
import Body from "../../images/yura/yura.png";
import Key from "../../images/yura/yura_key.png";
import number from "../../images/yura/02.png";
import logo from "../../images/yura/YuraName.png";
import TalentCore from '../../component/talentCore';

const yura_details = {
   name: `yura`,
   description: `正義感あふれる少女。
   アイドルを志し、《PROJECT NEBULA》のメンバーに加わった。
   普段はおっとりしているが、怒ると怖い。
   とっても優しい、みんなの癒しキャラ。`,
   slogan_top: `「正しいことって、なんなんだろう」`,
   slogan_bot: `「ゆらの成長する姿を、見守っててください！」`,
   twitter: "https://twitter.com/yurayura_usui",
   youtube: "https://www.youtube.com/@yurayurausui",
   hashtag: "#碓氷ゆら",
   illustrator: "とき",
   illustrator_link: "https://twitter.com/toki_ship8",
   modeler: "たかさき咲",
   modeler_link: "https://twitter.com/takasaki_saki",
   number: number,
   logo: logo,
   keyIllust: Key,
   baseIllust: Body
}

const Usui_Yura = () => {
   return (
      <TalentCore details={yura_details}/>
   )
}

export default Usui_Yura